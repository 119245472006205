import React, { Suspense } from 'react';

import { ProtectedRoute } from '../routes/ProtectedRoutes';
import { PageLoader } from '../components/PageLoader';

export const authWrapper = (component, state = 'auth', allowedRoles = []) => (
    <Suspense fallback={<PageLoader />}>
        <ProtectedRoute state={state} allowedRoles={allowedRoles}>
            {component}
        </ProtectedRoute>
    </Suspense>
);