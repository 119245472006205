import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getFeaturedIndustries: builder.query({
      query: () => ({
        url: `/catalog-service/industries/get-featured-industries`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result
    }),
    getIndustryProducts: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-product/get-industry-product?industryId=${payload.industryId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      })
    }),
    getSearchIndustries: builder.query({
      query: (payload) => ({
        url: `catalog-service/industries//search-end-root-industries?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result

    }),
    getFeaturedIcons: builder.query({
      query: () => ({
        url: `catalog-service/industries/featured-icons`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result

    }),

    getIndustryProduct: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-product/get-industry-product?industryId=${payload.industryId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAvailableCarriers: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/carrier-availability/available-carriers?naicsCode=${payload.naicsCode}&state=${payload.state}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCatalogSettings: builder.query({
      query: (payload) => ({
        url: `/company/agency-catalog-settings?agencyId=${payload.agencyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
         Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getApplicationData: builder.query({
      query: (payload) => ({
        url: `catalog-service/catalog-application/get-application-data?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      transformResponse: (response) => response.result
    }),


    addServiceRequest: builder.mutation({
      query: (payload) => ({
        url: '/service-request/create',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),
    addApplication: builder.mutation({
      query: () => ({
        url: `catalog-service/catalog-application/create-application`,
        method: 'POST',
        body: {},
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),

    addQuestionData: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-question/stimulate-question-data-v2',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),
    submitQuestions: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/form/submit',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),

    startApplication: builder.mutation({
      query: (payload) => ({
        url: 'catalog-service/catalog-application/start-application',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
         Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    
    startApplicationByAdmin: builder.mutation({
      query: (payload) => ({
        url: 'catalog-service/catalog-application/start-application-admin',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
         Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateApplicationData: builder.mutation({
      query: (payload) => ({
        url: 'catalog-service/catalog-application/update-application-data',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
         Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getProductQuestionList: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-question/get-product-questions-v2',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),
    generateForm: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/form/generate-form',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),
    getShareApplicationLink: builder.mutation({
      query: (payload) => ({
        url: '/new-application/get-share-application-link',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`

         
        }
      }),
      invalidatesTags: ['Post']
    }),
    getShareApplicationLinkV2: builder.mutation({
      query: (payload) => ({
        url: '/new-application/get-share-application-link-v2',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`

         
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateServiceRequest: builder.mutation({
      query: (payload) => ({
        url: '/service-request/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
         
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateCurrentEditor: builder.mutation({
      query: (payload) => ({
        url: '/application/share-application/update-current-editor',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddServiceRequestMutation,
  useGetIndustryProductsQuery,
  useUpdateServiceRequestMutation,
  useGetFeaturedIndustriesQuery,
  useLazyGetSearchIndustriesQuery,
  useLazyGetIndustryProductQuery,
  useGetProductQuestionListMutation,
  useGenerateFormMutation,
  useAddQuestionDataMutation,
  useStartApplicationMutation,
  useGetApplicationDataQuery,
  useUpdateApplicationDataMutation,
  useStartApplicationByAdminMutation,
  useGetShareApplicationLinkMutation,
  useUpdateCurrentEditorMutation,
  useGetFeaturedIconsQuery,
  useLazyGetAvailableCarriersQuery,
  useGetAvailableCarriersQuery,
  useAddApplicationMutation,
  useGetShareApplicationLinkV2Mutation,
  useGetCatalogSettingsQuery,
  useSubmitQuestionsMutation
} = catalogApi;
