/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const initialConfigState = {
  language: "en",
  data: null,
  paymentSelected: {
    status: false,
    value: "",
    id:''
  },
  apiData: {
    offset: 0,
    limit: 25,
    search: ""
  }
};

// Demo Redux Slice
export const configSlice = createSlice({
  name: "appConfig",
  initialState: initialConfigState,
  reducers: {
    setConfigData(state, action) {
      state.data = action.payload;
    },
    setApiData(state, action) {
      state.apiData = action.payload;
    },
    setPaymentSelected(state, action) {
      state.paymentSelected = action.payload;
    }
  }
});

export const { setConfigData } = configSlice.actions;
export const { setApiData } = configSlice.actions;
export const { setSnackBar } = configSlice.actions;
export const { setPaymentSelected } = configSlice.actions;

export const selectconfigDataState = (state) => state.appConfig?.data;
export const selectApiData = (state) => state.appConfig?.apiData;
export const selectedPayment = (state) => state.appConfig?.paymentSelected;

export const selectLanguageState = (state) => state?.appConfig?.language;
