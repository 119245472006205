import { createSlice } from '@reduxjs/toolkit';

export const initialSnackbarState = { 
    snackbar:{
        openSnackbar:false,
        snackbarData:{
          message:'',
          severity:''
        }
      } 
 };

// Snackbar Redux Slice
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState:initialSnackbarState,
  reducers: {
    setSnackbar(state, action) {
        state.snackbar = action.payload;
    }
  }
});

export const { setSnackbar } = snackbarSlice.actions;

export const selectSnackbarState = (state) => state.snackbar;
