import { io } from 'socket.io-client';

import { SOCKET_BASE_URL, SOCKET_SITE_PATH } from 'constant/apiConstant';


export const socket = io(SOCKET_BASE_URL, {
    path: SOCKET_SITE_PATH,
    autoConnect: false,
    transports: ['polling']
    // transports: ['websocket'],
    
  });