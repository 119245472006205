/* eslint-disable no-unreachable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getReports: builder.query({
      query: (payload) => ({
        url: `/reports/config?type=${payload.type}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    getDynamicData: builder.query({
      query: (payload) => {
        const { BASE_URL, URL, params } = payload.apiData;  
        const dynamicParams = params
        .map((param) => {
          if (param.key === "limit") {
            return { key: "limit", value: param?.value || 25 };
          }
          if (param.key === "offset") {
            return { key: "offset", value: param?.value || 0 };
          }
          return { key: param.key, value: param.value }; // Default case for other parameters
        })
        .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) // Convert objects to "key=value"
        .join('&'); // Join the query strings with "&"        
    
        // Construct the final URL with dynamic params
        const url = `${BASE_URL}${URL}?${dynamicParams}`;
    
        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-API-KEY': API_KEY,
            Authorization: `Bearer ${payload.token}`
          }
        };
      }
    })
    
  })
});

export const { useLazyGetReportsQuery, useLazyGetDynamicDataQuery } = reportApi;
