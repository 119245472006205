/* eslint-disable object-curly-newline */
import { createTheme } from "@mui/material";

import {
  blackColor,
  darkGreyColor,
  disabledButtonColor,
  mediumGreyColor,
  primaryColor,
  primaryHoverColor,
  whiteColor
} from "./colors";
import { subtitle1, caption, h6 } from "./typography";

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1100,
    lg: 1324,
    xl: 1920
  }
};

export const theme = createTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: whiteColor },
    lightGrey: { main: darkGreyColor }
  },
  breakpoints: customBreakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: { "*": { fontFamily: "Roboto, sans-serif" } }
    },
    MuiSnackbar:{
      styleOverrides:{
        root:{
          zIndex:1234343434
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            color: whiteColor,
            backgroundColor: primaryColor,
            textTransform: "capitalize",
            padding: "12px",
            fontSize: "1rem",
            "&:hover": { backgroundColor: primaryHoverColor },
            "&:disabled": {
              backgroundColor: disabledButtonColor,
              color: whiteColor
            }
          }
        },
        {
          props: { variant: "secondary" },
          style: {
            color: primaryColor,
            backgroundColor: whiteColor,
            textTransform: "initial",
            fontWeight: 600,
            border: `1px solid ${primaryColor}`,
            "&:hover": {
              border: `1px solid ${primaryColor}`,
              backgroundColor: whiteColor,
              color: primaryColor,
              ".MuiButton-startIcon path['stroke-linejoin=\"round\"']": {
                stroke: "red"
              }
            },
            "&:disabled": { border: `1px solid ${mediumGreyColor}` }
          }
        }
      ]
    },

    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          margin: "0px 10px",
          color: ownerState.selected ? blackColor : darkGreyColor,
          fontWeight: "bold",
          "&.Mui-selected": {
            color: ownerState.selected ? blackColor : darkGreyColor
          }
        })
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          background: whiteColor,
          boxShadow: "0px 4px 6px #7F7E8912",
          borderRadius: "4px"
        })
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          color: blackColor,
          font: "normal normal bold 20px/26px Roboto"
        })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {  // Target the label of FormControlLabel
          fontSize: '1rem',
          color:'#39262F'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          color: "palegreen"
        })
      }
    }
  }
});

theme.typography.caption = caption(theme);
theme.typography.subtitle1 = subtitle1(theme);
theme.typography.h6 = h6(theme);
