import { createSlice } from "@reduxjs/toolkit";

export const initialState = { indications: [], indication: null };

export const indicationsSlice = createSlice({
  name: "indications",
  initialState,
  reducers: {
    setIndication(state, action) {
      state.indication = action.payload;
    },
    setIndications(state, action) {
      if (Array.isArray(action.payload)) {
        state.indications = [...action.payload];
      } else {
        state.indications = [...state.indications, action.payload];
      }    },
    deleteIndications(state, action) {
      const filterData = state.indications?.filter(
        (item) => item.submissionResponseId !== action.payload
      );
      state.indications = filterData;
    }
  }
});

export const { setIndication, setIndications, deleteIndications } =
  indicationsSlice.actions;

export const selectedIndication = (state) => state.indication;
export const selectedIndications = (state) => state.indications;
