import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PageLoader } from 'components/Loader/PageLoader';

import { path } from '../constant/pathLinksConstant';
import { selectLoaderState } from '../redux/modules';
import { useAuth } from '../hooks/useAuth';


export const ProtectedRoute = ({ children, state, allowedRoles }) => {

    const { user } = useAuth();

    const loaderState = useSelector(selectLoaderState);
    if (!user && state === 'auth') {
        return <Navigate to={path.app.login} replace />;
    }

    if (state === 'auth' && allowedRoles?.length && user?.role?.slug) {
        if (!allowedRoles.includes(user.role.slug)) {
            return <Navigate to="/" replace />;
        }
        return children;
    }


    return (
        <>
            {loaderState?.loading && <PageLoader dark={loaderState.dark} />}
            {children}
        </>
    );
};