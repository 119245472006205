import { path } from './pathLinksConstant';

export const AUTH_ROLES = {
  AGENCY_ADMIN: 'AGENCY_ADMIN',
  AGENCY_EMPLOYEE: 'AGENCY_EMPLOYEE',
  PORTAL_ADMIN: 'PORTAL_ADMIN',
  PORTAL_EMPLOYEE: 'PORTAL_EMPLOYEE',
  MGA_ADMIN: 'MGA_ADMIN',
  MGA_EMPLOYEE: 'MGA_EMPLOYEE',
  AGENCY_CUSTOMER: 'AGENCY_CUSTOMER',
  REFERRAL_CUSTOMER:'REFERRAL_CUSTOMER',
  PORTAL_SUB_ADMIN:'PORTAL_SUB_ADMIN'

};

export const fallbackRoute = {
  AGENCY_ADMIN: path.applications.root,
  AGENCY_EMPLOYEE: path.applications.root,
  PORTAL_ADMIN: path.applications.start,
  PORTAL_SUB_ADMIN: path.applications.start,
  PORTAL_EMPLOYEE: path.products.root,
  MGA_ADMIN: path.products.root,
  MGA_EMPLOYEE: path.products.root,
  AGENCY_CUSTOMER: path.applications.root,
  REFERRAL_CUSTOMER: path.applications.root
  

};

export const superAdminAccessRoles = [
  AUTH_ROLES.PORTAL_ADMIN,
  AUTH_ROLES.PORTAL_EMPLOYEE,
  AUTH_ROLES.MGA_ADMIN,
  AUTH_ROLES.MGA_EMPLOYEE,
  AUTH_ROLES.AGENCY_CUSTOMER,
  AUTH_ROLES.REFERRAL_CUSTOMER,
  AUTH_ROLES.PORTAL_SUB_ADMIN
];

export const AgencyAdminAccessRoles = [AUTH_ROLES.AGENCY_ADMIN, AUTH_ROLES.AGENCY_EMPLOYEE, AUTH_ROLES.PORTAL_ADMIN, AUTH_ROLES.PORTAL_SUB_ADMIN];

export const AgencyEmpAccessRoles = [AUTH_ROLES.AGENCY_EMPLOYEE];
