import { createSlice } from '@reduxjs/toolkit';

export const initialButtonState = { 
    bottomButton:{
        buttonDisabled:true,
        buttonText:''
      } 
 };

// Snackbar Redux Slice
export const bottomButtonSlice = createSlice({
  name: 'bottomButton',
  initialState:initialButtonState,
  reducers: {
    setBottomButton(state, action) {
        state.bottomButton = action.payload;
    }
  }
});

export const { setBottomButton } = bottomButtonSlice.actions;

export const selectBottomButtonState = (state) => state.bottomButton;
