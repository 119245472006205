import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const serviceRequestApi = createApi({
  reducerPath: 'serviceRequestApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getServiceRequesList: builder.query({
      query: (payload) => ({
        url: `/service-request/list?limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}&requestType=${payload.requestType}&requestStatus=${payload.requestStatus}&accountId=${payload.accountId}&carrierId=${payload.carrierId}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getServiceRequesDetails: builder.query({
      query: (payload) => ({
        url: `/service-request/get?serviceRequestUuid=${payload.serviceRequestUuid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getRequestByPolicy: builder.query({
      query: (payload) => ({
        url: `/service-request/get-request-by-policy?policyId=${payload.policyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getRequestBody: builder.query({
      query: (payload) => ({
        url: `/service-request/get-request-body?requestType=${payload.requestType}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

  
    getDynamicData: builder.query({
      query: (payload) => {
        const { BASE_URL, URL, requiredParams } = payload.apiData.APICall;
        const dynamicParams = requiredParams
          .map((param) => {
            if (param === 'searchBy') {
              return `searchBy=${encodeURIComponent(payload.searchBy)}`;
            }
            if (param === 'agencyId') {
              return `agencyId=${payload.agencyId}`;
            }
            return '';
          })
          .join('&');
        const url = `${BASE_URL}${URL}?${dynamicParams}&limit=${payload.limit}&offset=${payload.offset}`;

        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-API-KEY': API_KEY,
            Authorization: `Bearer ${payload.token}`
          }
        };
      }
    }),
    addServiceRequest: builder.mutation({
      query: (payload) => ({
        url: '/service-request/create',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateServiceRequest: builder.mutation({
      query: (payload) => ({
        url: '/service-request/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useLazyGetRequestBodyQuery,
  useLazyGetDynamicDataQuery,
  useAddServiceRequestMutation,
  useGetServiceRequesListQuery,
  useGetServiceRequesDetailsQuery,
  useUpdateServiceRequestMutation,
  useGetRequestByPolicyQuery
} = serviceRequestApi;
