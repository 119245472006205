import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getApplicationCarrier: builder.query({
      query: (payload) => ({
        url: `/company-relationship/get-product-carriers?productId=${payload.productId}&state=${payload.state}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`

        }
      }),
      invalidatesTags: ['Post'],
      transformResponse: (response) => response.result
    }),

    // getApplicationCarrierData: builder.mutation({
    //   query: (payload) => ({
    //     url: `/company-relationship/get-product-carriers?productId=${payload.productId}&state=${payload.state}`,
    //     method: 'GET',
    //     headers: {
    //       'Content-type': 'application/json; charset=UTF-8',
    //       'X-API-KEY': API_KEY,
    //       Authorization: `Bearer ${payload.token}`

    //     }
    //   }),
    //   invalidatesTags: ['Post']
    // })

    appCarrrier: builder.mutation({
      query: (payload) => ({
        url: `/company-relationship/get-product-carriers?productId=${payload.productId}&state=${payload.state}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`

        }
      }),
      transformResponse: (response) => response.result
    }),
    verifyConsumerCustomerToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-consumer-customer-token',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),
    verifyPartnerToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-partner-token',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),
    getAgencies: builder.query({
      query: (payload) => ({
        url: `/company/get-agency-list?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgents: builder.query({
      query: (payload) => ({
        url: `/company/agents-list?agencyId=${payload.agencyId}&searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    })
  })
});


export const { 
  useGetApplicationCarrierQuery, 
  useAppCarrrierMutation,
  useVerifyConsumerCustomerTokenMutation,
  useVerifyPartnerTokenMutation,
  useGetAgenciesQuery,
  useGetAgentsQuery 
} = applicationApi;
