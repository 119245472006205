
import React, { createContext, useMemo } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);

  // call this function when you want to authenticate the user
  const login = (data) =>
    new Promise((resolve, reject) => {
      try {
        setUser(data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });

  // call this function to sign out logged in user
  const logout = () =>
    new Promise((resolve, reject) => {
      try {
        setUser(null);
        resolve();
      } catch (e) {
        reject(e);
      }
    });

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
