/* eslint-disable no-unused-vars */


import {
  combineReducers,
  configureStore,
  createListenerMiddleware
} from '@reduxjs/toolkit';
// Redux slices
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, createTransform } from 'redux-persist';

import { modalSlice,loaderSlice, snackbarSlice,bottomButtonSlice,indicationsSlice,currentEditorSlice} from './modules';
import { userApi } from './modules/user';
import { configApi, configSlice } from './modules/config';
import { applicationApi } from './modules/application';
import { serviceRequestApi } from './modules/serviceRequest';
import { companyApi } from './modules/company';
import { policyApi } from './modules/policy';
import { catalogApi } from './modules/catalog';
import { reportApi } from './modules/report';
import { companyProductApi } from './modules/companyProducts';
import {
  loginApi,
  resetPasswordApi,
  signUpApi,
  forgotPasswordApi
} from './modules/onboarding';
import { applicationListApi } from './modules/application-list';

const listenerMiddleware = createListenerMiddleware();

const rootReducer = combineReducers({
  [modalSlice.name]: modalSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [currentEditorSlice.name]: currentEditorSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [bottomButtonSlice.name]: bottomButtonSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  [indicationsSlice.name]: indicationsSlice.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [companyProductApi.reducerPath]: companyProductApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [serviceRequestApi.reducerPath]: serviceRequestApi.reducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [applicationListApi.reducerPath]: applicationListApi.reducer
});

const appConfigTransform = createTransform(
  // Transform out (exclude 'paymentSelected' from appConfig)
  (inboundState) => {
    const { paymentSelected, ...rest } = inboundState;
    return rest; // Persist everything except 'paymentSelected'
  },
  // Transform in (no change needed here)
  (outboundState) => outboundState,
  { whitelist: ['appConfig'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['appConfig'],
  blacklist: ['indications'], // Main reducer keys to blacklist
  transforms: [appConfigTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        // Ignore these actions as they contain non-serializable values
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore specific paths in the state for non-serializable values
        ignoredPaths: ['register', 'rehydrate']
      }
    })
      .prepend(listenerMiddleware.middleware)
      .concat(
        configApi.middleware,
        reportApi.middleware,
        loginApi.middleware,
        signUpApi.middleware,
        companyProductApi.middleware,
        companyApi.middleware,
        policyApi.middleware,
        catalogApi.middleware,
        serviceRequestApi.middleware,
        resetPasswordApi.middleware,
        forgotPasswordApi.middleware,
        applicationApi.middleware,
        applicationListApi.middleware,
        userApi.middleware
      ),
  devTools: true
});

setupListeners(store.dispatch);
export const persistor = persistStore(store);
