import React from 'react';
import { Box } from '@mui/material';

import Animation from '../../assets/animation.gif';

const pageLoaderContainerStyle = {
    display: 'flex',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(0 0 0 / 75%)',
    zIndex: 99999
};

export function PageLoader({ dark = false }) {
    const bgFullOpacity = dark ? { background: 'rgb(0 0 0 / 100%)' } : '#fff';
    return (
        <Box sx={{ ...pageLoaderContainerStyle, ...{ bgFullOpacity } }}>
            <img src={Animation} alt="PDF icon" width="200px" />
        </Box>
    );
}
