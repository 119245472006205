/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import SecureLS from 'secure-ls';

const secureLS = new SecureLS({ isCompression: false });

export const useLocalStorage = (keyName, defaultValue) => {
  const setEncryptedValue = (value) => {
    try {
      secureLS.set(keyName, value);
    } catch (err) {
      console.error('ERROR: LocalStorage encryption:', err);
    }
  };
  const [storedValue, setStoredValue] = useState(() => {
    
    try {
      const encryptedValue = window.localStorage.getItem(keyName);
      if (encryptedValue) {
        const decryptedValue = secureLS.get(keyName);
        return decryptedValue !== undefined ? decryptedValue : defaultValue;
      }
      setEncryptedValue(defaultValue);
      return defaultValue;
    } catch (err) {
      console.error('ERROR: LocalStorage decryption:', err);
      return defaultValue;
    }
  });

  

  const setValue = (newValue) => {
    setEncryptedValue(newValue);
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
