/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { Provider, useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { theme } from 'theme';
import { SocketProvider } from 'context/SocketProvider';
import { AppRouter } from 'routes/appRoutes';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { setConfigData, useGetConfigDataQuery } from './redux/modules/config';
import { store } from './redux';
import { AuthProvider } from './context/AuthProvider';

function InnerApp() {
  const { data: configData } = useGetConfigDataQuery({}, {skip:false});
  const dispatch = useDispatch();

  const [buildId, setBuildId] = useLocalStorage('buildId', null);
  
  useEffect(() => {
    if (configData) {
      if(!buildId) {
        setBuildId(configData.buildId);
        dispatch(setConfigData(configData));
      }
      else if(buildId && buildId !== configData.buildId){
        // case: app is upgraded but browser loads app from cache, this is to get updated version  
        localStorage.clear();
        setBuildId(configData.buildId);
        window.location.reload(true);
      }
      else dispatch(setConfigData(configData));
    }
  }, [configData]);

  return <RouterProvider router={AppRouter} />;
}

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SocketProvider>
              <InnerApp />
            </SocketProvider>
          </ThemeProvider>
        </Provider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
