import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { API_BASE_URL, API_KEY } from "constant/apiConstant";

const endpointSlug = "new-application";

export const applicationListApi = createApi({
  reducerPath: "applicationListApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getApplicationListData: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/application-list-v2?limit=${payload.limit}&offset=${payload.offset}&search=${payload.search}&archived=${payload.archived}&active=${payload.active}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getCurrentScreen: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-current-app-screen?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    currentScreen: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/application-current-screen?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductPageData: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-app-product-page-data?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductFormData: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-app-product-form-data?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuoteFormData: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-app-quote-form-data?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuoteProductFormData: builder.query({
      query: (payload) => ({
        url: `application/quote/get-app-quote-form-data?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductIndication: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-application-products?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getIndicationQuotes: builder.query({
      query: (payload) => ({
        url: payload.productId
          ? `${endpointSlug}/get-indication-quotes?applicationId=${payload.applicationId}&productId=${payload.productId}`
          : `${endpointSlug}/get-indication-quotes?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuoteList: builder.query({
      query: (payload) => ({
        url: payload.productId
          ? `application/quote/get-quote-list?applicationId=${payload.applicationId}&productId=${payload.productId}`
          : `application/quote/get-quote-list?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getReviewQuotes: builder.query({
      query: (payload) => ({
        url: `application/carrier-application/get-review-quote?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getActiveScreen: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-active-screen?submissionResponseId=${payload.submissionResponseId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuoteActiveScreen: builder.query({
      query: (payload) => ({
        url: `application/quote/current-active-screen?submissionResponseId=${payload.submissionResponseId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getSignedProposalDetails: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-sign-proposal-details?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getSignedProposalUrl: builder.query({
      query: (payload) => ({
        url: `${endpointSlug}/get-sign-proposal-url?submissionResponseId=${payload.submissionResponseId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSignatureDetails: builder.query({
      query: (payload) => ({
        url: `application/signature/sign-proposal-details?submissionResponseId=${payload.submissionResponseId}&applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getBindCompanyDocuments: builder.query({
      query: (payload) => ({
        url: `/company-document/get-bind-company-documents?applicationId=${payload.applicationId}`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    uploadBindDocuments: builder.mutation({
      query: (payload) => ({
        url: '/company-document/upload-bind-documents',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    initiateApplication: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/initiate-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    adminInitiateApplication: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/initiate-application-admin`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getSignatureLink: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/get-signature-link`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSignatureLinkV2: builder.mutation({
      query: (payload) => ({
        url: `application/signature/get-signature-link`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    generateSignaturePackage: builder.mutation({
      query: (payload) => ({
        url: `application/signature/generate-package`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    updateQuoteScreen: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/update-quote-active-screen`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    collectData: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/submit-initial-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    submitFinalApplication: builder.mutation({
      query: (payload) => ({
        url: `application/carrier-application/new-submit-final-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    submitFinalQuote: builder.mutation({
      query: (payload) => ({
        url: `application/quote/submit-final-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    preBindApplication: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/pre-bind-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    preBindQuote: builder.mutation({
      query: (payload) => ({
        url: `application/quote/pre-bind-quote`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    bindApplication: builder.mutation({
      query: (payload) => ({
        url: `${endpointSlug}/bind-application`,
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),

    uploadSignedDocs: builder.mutation({
      query: (payload) => ({
        url: "/new-application/upload-signed-docs",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),

    verifyApplicationEmail: builder.mutation({
      query: (payload) => ({
        url: "/user/verify-application-email",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY
        }
      }),
      invalidatesTags: ["Post"]
    }),

    getSignProposal: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/new-application/get-sign-proposal-url?submissionResponseId=${payload.submissionResponseId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response
    }),
    getPaymentDetails: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/payment/get-payment-details?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}&paymentMode=${payload.paymentMode}&payer=${payload.payer}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPaymentPlans: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `payment/get-carrier-payment-plans?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSignatureOptions: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `${endpointSlug}/get-signature-options?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductCarriers: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `${endpointSlug}/product-carriers?applicationId=${payload.applicationId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSignatureOptionsV2: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `application/signature/get-signature-options?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPolicyDetails: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `${endpointSlug}/get-policy-details?submissionResponseId=${payload.submissionResponseId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCustomFrom: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/application/custom-form/custom-form?token=${payload.token}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY        
}
      }),
      transformResponse: (response) => response.result
    }),
    getPaymentMethods: builder.mutation({
      query: (payload) => ({
        url: "/payment/get-payment-method-options",
        method: "POST",
        body: {
          applicationId: payload.applicationId,
          submissionResponseId: payload.submissionResponseId
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    verfiyCarrierPaymentDetails: builder.mutation({
      query: (payload) => ({
        url: '/payment/verify-carrier-payment-details-v2',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    verifyPayment: builder.mutation({
      query: (payload) => ({
        url: "/payment/verify-payment-details-v2",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    updatePayer: builder.mutation({
      query: (payload) => ({
        url: "/payment/update-payer",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    copyPaymentLink: builder.mutation({
      query: (payload) => ({
        url: "/payment/get-customer-payment-link",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ["Post"]
    }),
    submitCustomFormData: builder.mutation({
      query: (payload) => ({
        url: "/application/custom-form/submit-custom-form-data",
        method: "POST",
        body: payload.data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-API-KEY": API_KEY
        }
      }),
      invalidatesTags: ["Post"]
    }),

    verifyAdminToken: builder.mutation({
      query: (payload) => ({
        url: '/user/verify-admin-token',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),
    archivedApplcation: builder.mutation({
      query: (payload) => ({
        url: '/new-application/archive',
        method: 'Post',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useGetApplicationListDataQuery,
  useInitiateApplicationMutation,
  useAdminInitiateApplicationMutation,
  useGetProductPageDataQuery,
  useGetCurrentScreenQuery,
  useGetProductFormDataQuery,
  useCollectDataMutation,
  useGetIndicationQuotesQuery,
  useGetQuoteListQuery,
  useGetProductIndicationQuery,
  usePreBindApplicationMutation,
  useGetQuoteFormDataQuery,
  useSubmitFinalApplicationMutation,
  useGetReviewQuotesQuery,
  useGetActiveScreenQuery,
  useLazyGetSignedProposalDetailsQuery,
  useUploadSignedDocsMutation,
  useBindApplicationMutation,
  useGetSignProposalQuery,
  useVerifyApplicationEmailMutation,
  useLazyGetSignedProposalUrlQuery,
  useGetSignatureLinkMutation,
  useGetPaymentDetailsQuery,
  useGetPaymentMethodsMutation,
  useVerifyPaymentMutation,
  useUpdateQuoteScreenMutation,
  useGetPolicyDetailsQuery,
  useGetSignatureOptionsQuery,
  useGetPaymentPlansQuery,
  useVerfiyCarrierPaymentDetailsMutation,
  useGetBindCompanyDocumentsQuery,
  useUploadBindDocumentsMutation,
  useVerifyAdminTokenMutation,
  useUpdatePayerMutation, 
  useCopyPaymentLinkMutation,
  useArchivedApplcationMutation,
  useCurrentScreenQuery,
  usePreBindQuoteMutation,
  useGetQuoteActiveScreenQuery,
  useGetQuoteProductFormDataQuery,
  useSubmitFinalQuoteMutation,
  useGenerateSignaturePackageMutation,
  useLazyGetSignatureDetailsQuery,
  useGetSignatureOptionsV2Query,
  useGetSignatureLinkV2Mutation,
  useGetCustomFromQuery,
  useSubmitCustomFormDataMutation,
  useGetProductCarriersQuery
} = applicationListApi;