/* eslint-disable no-undef */
const { TextField } = require('@mui/material');
const { default: styled } = require('styled-components');

export const FormTextInput = styled(TextField)({
  '& .MuiFormHelperText-root': {
    color: 'red',
    marginLeft: '2px'
  }
});
